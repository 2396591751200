import React, { Component } from "react";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";

import { uploadDocumentService } from "../services/UploadDocumentService";

import { Form, Button, Input, Upload, Modal, Row, Col, Space, Spin, Image, InputNumber } from "antd";
import { RightCircleOutlined, UploadOutlined, SaveOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import SelectTypeNotice from "../components/selects/SelectTypeNotice";

class DocumentsUploadNotice extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      inputVehicleEngine: "",
      typeNotice: "",
      loadingButton: false,

      inputImage: [],
      validImage: false,
      inputImagePreview: "",

      modalMismatchVisible: false,
      modalInputDesc: "",

      modalConfirmVisible: false,
      editMode: false,
      editButtonText: "Edit",

      resultObject: {},
      resultNoNotice: "",
      resultCustomerName: "",
      resultCustomerAddress: "",
      resultEngineNumber: "",
      resultChassisNumber: "",
      resultLicensePlateNumber: "",
      resultBpkbNumber: "",
      resultExpireAt: "",
      resultNikNohp: "",
      resultBbnkb: 0,
      resultBbnkbOpsen: 0,
      resultPkb: 0,
      resultPkbOpsen: 0,
      resultSwdkllj: 0,
      resultPenerbitanStnk: 0,
      resultPenerbitanTnkb: 0,
      resultJumlah: 0,
      resultOwnershipOrder: 0,
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  changeInputVehicleEngine = (v) => {
    this.setState({
      inputVehicleEngine: v.target.value,
    });
  };

  changeTypeNotice = (v) => {
    this.setState({
      typeNotice: v,
    });
  };

  handleFieldChange = (field, value) => {
    // console.log("Input =", this.state.resultBbnkb);
    // console.log("Result API =", this.state.resultObject?.bbnkb);
    this.setState({ [field]: value });

    // console.log("Input =", this.state.resultBbnkb);
    // console.log("Result API =", this.state.resultObject?.bbnkb);
  };

  beforeUploadFile = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Modal.error({
        title: "Validation Error",
        content: "Format File Bukan JPG/PNG",
      });
    }
    if (isJpgOrPng) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({
          inputImagePreview: event.target.result,
          inputImage: file,
          validImage: true,
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        validImage: false,
      });
    }
    return false;
  };

  onExtractDokumen = async () => {
    try {
      const { typeNotice, inputVehicleEngine, inputImage, validImage } = this.state;

      if (!typeNotice) {
        throw new Error("Nomor Dokumen tidak valid");
      }
      if (!inputVehicleEngine) {
        throw new Error("Nomor Mesin tidak valid");
      }
      if (!validImage) {
        throw new Error("File Dokumen tidak valid");
      }
      this.setState({
        loadingButton: true,
      });

      const formData = new FormData();
      formData.append("notice_type", typeNotice);
      formData.append("vehicle_engine_number", inputVehicleEngine);

      if (validImage) {
        formData.append("document", inputImage);
      }

      let extractedData = await uploadDocumentService.extractNoticeJabar(formData);
      if (typeNotice === "JABAR 2025" || typeNotice === "JABAR 2024" || typeNotice === "MANADO 2025" || typeNotice === "GORONTALO 2025" || typeNotice === "GORONTALO 2024") {
        extractedData = await uploadDocumentService.extractNoticeNew(formData);
      } else {
        extractedData = await uploadDocumentService.extractNoticeJabar(formData);
      }

      this.setState({
        loadingButton: false,
        resultObject: extractedData?.data?.data,
        resultNoNotice: extractedData?.data?.data?.no_notice,
        resultCustomerName: extractedData?.data?.data?.customer_name,
        resultCustomerAddress: extractedData?.data?.data?.customer_address,
        resultEngineNumber: extractedData?.data?.data?.engine_number,
        resultChassisNumber: extractedData?.data?.data?.chassis_number,
        resultLicensePlateNumber: extractedData?.data?.data?.license_plate_number,
        resultBpkbNumber: extractedData?.data?.data?.bpkb_number,
        resultExpireAt: extractedData?.data?.data?.expire_at,
        resultNikNohp: extractedData?.data?.data?.nik_nohp,
        resultBbnkb: extractedData?.data?.data?.bbnkb?.toLocaleString(),
        resultBbnkbOpsen: extractedData?.data?.data?.bbnkb_opsen?.toLocaleString(),
        resultPkb: extractedData?.data?.data?.pkb?.toLocaleString(),
        resultPkbOpsen: extractedData?.data?.data?.pkb_opsen?.toLocaleString(),
        resultSwdkllj: extractedData?.data?.data?.swdkllj?.toLocaleString(),
        resultPenerbitanStnk: extractedData?.data?.data?.penerbitan_stnk?.toLocaleString(),
        resultPenerbitanTnkb: extractedData?.data?.data?.penerbitan_tnkb?.toLocaleString(),
        resultJumlah: extractedData?.data?.data?.jumlah?.toLocaleString(),
        resultOwnershipOrder: extractedData?.data?.data?.ownership_order,
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  onMismatchData = () => {
    try {
      const { typeNotice, inputVehicleEngine, validImage, resultObject } = this.state;

      if (Object.keys(resultObject).length < 1) {
        throw new Error("Belum mengextract data");
      }

      if (!typeNotice) {
        throw new Error("Tipe Notice tidak valid");
      }
      if (!inputVehicleEngine) {
        throw new Error("Nomor Mesin tidak valid");
      }
      if (!validImage) {
        throw new Error("File Dokumen tidak valid");
      }

      this.setState({ modalMismatchVisible: true });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  onActiveEdit = () => {
    try {
      if (Object.keys(this.state.resultObject).length < 1) {
        throw new Error("Belum mengextract data");
      }
      this.setState({ modalConfirmVisible: true });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  onCancelEdit = () => {
    const { resultObject } = this.state;

    this.setState({
      editMode: false,
      resultNoNotice: resultObject?.no_notice,
      resultCustomerName: resultObject?.customer_name,
      resultCustomerAddress: resultObject?.customer_address,
      resultEngineNumber: resultObject?.engine_number,
      resultChassisNumber: resultObject?.chassis_number,
      resultLicensePlateNumber: resultObject?.license_plate_number,
      resultBpkbNumber: resultObject?.bpkb_number,
      resultExpireAt: resultObject?.expire_at,
      resultNikNohp: resultObject?.nik_nohp,
      resultBbnkb: resultObject?.bbnkb,
      resultBbnkbOpsen: resultObject?.bbnkb_opsen,
      resultPkb: resultObject?.pkb,
      resultPkbOpsen: resultObject?.pkb_opsen,
      resultSwdkllj: resultObject?.swdkllj,
      resultPenerbitanStnk: resultObject?.penerbitan_stnk,
      resultPenerbitanTnkb: resultObject?.penerbitan_tnkb,
      resultJumlah: resultObject?.jumlah,
      resultOwnershipOrder: resultObject?.ownership_order,
      modalInputDesc: "",
    });
  };

  onConfirmEdit = () => {
    this.setState({ editMode: true, modalConfirmVisible: false, modalInputDesc: "" });
  };

  changeModalInputDesc = (v) => {
    this.setState({
      modalInputDesc: v.target.value,
    });
  };

  handleCancel = () => {
    this.setState({ modalMismatchVisible: false, modalConfirmVisible: false });
  };

  handleReportData = async (is_data_valid) => {
    try {
      const { ...currentState } = this.state;
      // console.log("X", is_data_valid);
      // return;

      if (Object.keys(currentState.resultObject).length < 1) {
        throw new Error("Belum mengextract data");
      }
      if (!currentState.typeNotice) {
        throw new Error("Tipe Notice tidak valid");
      }
      if (!currentState.inputVehicleEngine) {
        throw new Error("Nomor Mesin tidak valid");
      }
      if (!currentState.validImage) {
        throw new Error("File Dokumen tidak valid");
      }

      if (currentState.editMode === true && !currentState.modalInputDesc) {
        throw new Error("Alasan tidak valid");
      }

      const formData = new FormData();

      const bbnkb = Number(currentState?.resultBbnkb?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const bbnkb_opsen = Number(currentState?.resultBbnkbOpsen?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const pkb = Number(currentState?.resultPkb?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const pkb_opsen = Number(currentState?.resultPkbOpsen?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const swdkllj = Number(currentState?.resultSwdkllj?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const penerbitan_stnk = Number(currentState?.resultPenerbitanStnk?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const penerbitan_tnkb = Number(currentState?.resultPenerbitanTnkb?.toString()?.replace(/[^0-9]/g, "")) || 0;
      const total_bbn = Number(currentState?.resultJumlah?.toString()?.replace(/[^0-9]/g, "")) || 0;

      const check_total = bbnkb + bbnkb_opsen + pkb + pkb_opsen + swdkllj + penerbitan_stnk + penerbitan_tnkb;
      const check_total_sulawesi = bbnkb + bbnkb_opsen + pkb + pkb_opsen + swdkllj;
      const selisih = total_bbn - check_total;
      const selisih_sulawesi = total_bbn - check_total_sulawesi;

      // console.log(`${bbnkb} + ${bbnkb_opsen} + ${pkb} + ${pkb_opsen} + ${swdkllj} + ${penerbitan_stnk} + ${penerbitan_tnkb} = ${check_total}`, `${total_bbn} - ${check_total} = ${selisih}`);
      // console.log(`${bbnkb} + ${bbnkb_opsen} + ${pkb} + ${pkb_opsen} + ${swdkllj} = = ${check_total_sulawesi}`, `${total_bbn} - ${check_total_sulawesi} = ${selisih_sulawesi}`);

      if (bbnkb < 0 || pkb < 0 || swdkllj < 35000 || total_bbn < 100000) {
        throw new Error("Nilai Biaya tidak valid");
      }

      if (selisih_sulawesi !== 0 && currentState.typeNotice.includes("MANADO")) {
        throw new Error(`SELISIH TOTAL ${selisih_sulawesi}, tolong hitung kembali data biaya`);
      } else if (selisih !== 0 && !currentState.typeNotice.includes("MANADO") && !currentState.typeNotice.includes("GORONTALO")) {
        throw new Error(`SELISIH TOTAL ${selisih}, tolong hitung kembali data biaya`);
      }

      formData.append("service_bureau_code", currentState.codeSVB);
      formData.append("vehicle_engine_number", currentState.inputVehicleEngine);
      formData.append("description", currentState.modalInputDesc);
      formData.append("is_data_valid", is_data_valid);
      formData.append("textract_no_notice", currentState?.resultNoNotice);
      formData.append("textract_customer_name", currentState?.resultCustomerName);
      formData.append("textract_customer_address", currentState?.resultCustomerAddress);
      formData.append("textract_engine_number", currentState?.resultEngineNumber);
      formData.append("textract_chassis_number", currentState?.resultChassisNumber);
      formData.append("textract_license_plate_number", currentState?.resultLicensePlateNumber);
      formData.append("textract_bpkb_number", currentState?.resultBpkbNumber);
      formData.append("textract_expire_at", currentState?.resultExpireAt);
      formData.append("textract_nik_nohp", currentState?.resultNikNohp);

      formData.append("textract_bbnkb", bbnkb);
      formData.append("textract_bbnkb_opsen", bbnkb_opsen);
      formData.append("textract_pkb", pkb);
      formData.append("textract_pkb_opsen", pkb_opsen);
      formData.append("textract_swdkllj", swdkllj);
      formData.append("textract_penerbitan_stnk", penerbitan_stnk);
      formData.append("textract_penerbitan_tnkb", penerbitan_tnkb);
      formData.append("textract_jumlah", total_bbn);

      formData.append("ownership_order", currentState?.resultOwnershipOrder);
      formData.append("payment_approved", !currentState?.editMode);

      if (currentState.validImage) {
        formData.append("document_image", currentState.inputImage);
      }

      this.setState({
        loadingButton: true,
      });

      await uploadDocumentService.uploadNotice(formData).then(async (data) => {
        Modal.success({
          title: "Process Success",
          content: "SUCCESS",
        });

        this.setState(this.initState);
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-document"]} defaultSelectedKeys={["m-upload-notice"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form ref={this.formInput} name="form-filter" layout="vertical">
              <Row>
                <Col xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                      <SelectTypeNotice value={this.state.typeNotice} onChangeValue={this.changeTypeNotice} />
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                      <Form.Item label="Nomor Mesin">
                        <Input value={this.state.inputVehicleEngine} onChange={this.changeInputVehicleEngine} placeholder="Nomor Mesin" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                      <br />
                      Upload Notice
                      <hr style={{ border: "1px solid #DDD" }} />
                      <Space.Compact>
                        <Form.Item style={{ display: this.state.hiddenImage }}>
                          <Upload maxCount={1} showUploadList={false} beforeUpload={this.beforeUploadFile}>
                            <Button icon={<UploadOutlined />} loading={this.state.loadingButton} style={{ width: "100%" }}>
                              Select File
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item>
                          <Button onClick={this.onExtractDokumen} type="primary" icon={<RightCircleOutlined />} title="Simpan" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                            Extract Dokumen
                          </Button>
                        </Form.Item>
                      </Space.Compact>
                      {this.state.inputImagePreview && (
                        <Form.Item>
                          <Image width={"inherit"} src={this.state.inputImagePreview} />
                        </Form.Item>
                      )}
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 18 }} lg={{ span: 18 }} md={{ span: 16 }} sm={{ span: 24 }} loading={this.state.loadingButton}>
                      <br />
                      Hasil Extract Dokumen
                      <span style={{ float: "right" }}>
                        Edit Mode : <strong>{this.state.editMode.toString().toUpperCase()}</strong>
                      </span>
                      <hr style={{ border: "1px solid #DDD" }} />
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }}>
                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Nomor Notice">
                                <Input value={this.state.resultNoNotice} placeholder="Nomor Notice" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultNoNotice", event.target.value)}></Input>
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Nama Konsumen">
                                <Input value={this.state.resultCustomerName} placeholder="Nama Konsumen" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultCustomerName", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Alamat Konsumen">
                                <Input value={this.state.resultCustomerAddress} placeholder="Alamat Konsumen" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultCustomerAddress", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="NIK / Nomor Telepon">
                                <Input value={this.state.resultNikNohp} placeholder="NIK / Nomor Telepon" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultNikNohp", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Tanggal Kadaluarsa">
                                <Input value={this.state.resultExpireAt} placeholder="Tanggal Kadaluarsa" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultExpireAt", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Kepemilikan Ke">
                                <Input value={this.state.resultOwnershipOrder} placeholder="Kepemilikan Ke" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultOwnershipOrder", event.target.value)} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 24 }} sm={{ span: 24 }}>
                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Nomor Mesin">
                                <Input value={this.state.resultEngineNumber} placeholder="Nomor Mesin" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultEngineNumber", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Nomor Rangka">
                                <Input value={this.state.resultChassisNumber} placeholder="Nomor Rangka" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultChassisNumber", event.target.value)} />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Nomor Polisi">
                                <Input value={this.state.resultLicensePlateNumber} placeholder="Nomor Polisi" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultLicensePlateNumber", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Nomor BPKB">
                                <Input value={this.state.resultBpkbNumber} placeholder="Nomor BPKB" disabled={!this.state.editMode} onChange={(event) => this.handleFieldChange("resultBpkbNumber", event.target.value)} />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya BBNKB">
                                <InputNumber
                                  value={this.state.resultBbnkb}
                                  placeholder="Biaya BBNKB"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultBbnkb", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya BBNKB Opsen">
                                <InputNumber
                                  value={this.state.resultBbnkbOpsen}
                                  placeholder="Biaya BBNKB Opsen"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultBbnkbOpsen", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya PKB">
                                <InputNumber
                                  value={this.state.resultPkb}
                                  placeholder="Biaya PKB"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultPkb", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya PKB Opsen">
                                <InputNumber
                                  value={this.state.resultPkbOpsen}
                                  placeholder="Biaya PKB Opsen"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultPkbOpsen", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya SWDKLLJ">
                                <InputNumber
                                  value={this.state.resultSwdkllj}
                                  placeholder="Biaya SWDKLLJ"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultSwdkllj", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya Penerbitan STNK">
                                <InputNumber
                                  value={this.state.resultPenerbitanStnk}
                                  placeholder="Biaya Penerbitan STNK"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultPenerbitanStnk", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Biaya Penerbitan TNKB">
                                <InputNumber
                                  value={this.state.resultPenerbitanTnkb}
                                  placeholder="Biaya Penerbitan TNKB"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultPenerbitanTnkb", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                              <Form.Item label="Total Biaya Samsat">
                                <InputNumber
                                  value={this.state.resultJumlah}
                                  placeholder="Total Biaya Samsat"
                                  disabled={!this.state.editMode}
                                  onChange={(value) => this.handleFieldChange("resultJumlah", value)}
                                  className="text-info"
                                  style={{ width: "100%", textAlign: "right" }}
                                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => value.replace(/[^0-9]/g, "")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ paddingLeft: 5, paddingRight: 5, display: this.state.editMode ? "block" : "none" }} xs={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                              <Form.Item label="Alasan Pengeditan">
                                <Input.TextArea placeholder="Contoh :  Nomor Notice 12345, Hasil 435 [Enter]" value={this.state.modalInputDesc} onChange={this.changeModalInputDesc} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                          <br />
                          <Form.Item>
                            <Space.Compact>
                              <Button onClick={() => this.onActiveEdit()} type="primary" icon={<EditOutlined />} style={{ background: "#ca2e2e", borderColor: "#ca2e2e", display: this.state.editMode ? "none" : "block" }} loading={this.state.loadingButton}>
                                Edit Data
                              </Button>
                              &nbsp;
                              <Button onClick={() => this.onCancelEdit()} type="primary" icon={<CloseOutlined />} style={{ background: "#ca2e2e", borderColor: "#ca2e2e", display: this.state.editMode ? "block" : "none" }} loading={this.state.loadingButton}>
                                Batal Edit
                              </Button>
                              &nbsp;
                              <Button onClick={() => this.handleReportData(true)} type="primary" icon={<SaveOutlined />} style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                                Simpan
                              </Button>
                              &nbsp;
                            </Space.Compact>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>

          <Modal visible={this.state.loadingButton} title="Loading" maskClosable={false} footer={null} closable={false}>
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
              <p style={{ marginTop: 16 }}>Loading...</p>
            </div>
          </Modal>

          <Modal
            title="Laporkan data Belum Sesuai"
            maskClosable={false}
            closable={false}
            visible={this.state.modalMismatchVisible}
            onCancel={this.handleCancel}
            footer={[
              <Button key="cancel" onClick={this.handleCancel} loading={this.state.loadingButton}>
                Batal
              </Button>,
              <Button key="report" type="primary" onClick={() => this.handleReportData(false)} loading={this.state.loadingButton}>
                Laporkan Data
              </Button>,
            ]}
          >
            <Input.TextArea placeholder="Contoh :  Nomor Notice 12345, Hasil 435 [Enter]" value={this.state.modalInputDesc} onChange={this.changeModalInputDesc} />
          </Modal>

          <Modal
            title="Konfirmasi"
            maskClosable={false}
            closable={false}
            visible={this.state.modalConfirmVisible}
            onCancel={this.handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={this.onConfirmEdit} loading={this.state.loadingButton}>
                Ya, saya yakin
              </Button>,
              <Button key="cancel" onClick={this.handleCancel} loading={this.state.loadingButton}>
                Batal
              </Button>,
            ]}
          >
            <p>Mengedit data Hasil Extract akan menjadikan data perlu di review terlebih dahulu sebelum melakukan pembayaran. Apakah Anda yakin ingin melanjutkan?</p>
          </Modal>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(DocumentsUploadNotice);
